import { AppState } from '../createStore';
import { createSelector } from 'reselect';
import { Reward, RewardType } from '@wix/ambassador-loyalty-rewards/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-accounts/types';

function loyaltyProgramSelectorInner(loyaltyAccount?: LoyaltyAccount, loyaltyRewards?: Reward[]) {
  const discountAmountReward = loyaltyRewards?.find((r) => r.rewardType === RewardType.DISCOUNT_AMOUNT && r.active);

  return {
    isActive: Boolean(discountAmountReward),
    pointsRequired: discountAmountReward?.pointsRequired || 0,
    currencyValue: Number(discountAmountReward?.discountAmount?.amount),
    pointsBalance: loyaltyAccount?.pointsBalance || 0,
    loyaltyRewardId: discountAmountReward?.id,
  };
}

export const loyaltyProgramSelector = createSelector(
  [(state: AppState) => state.session.loyaltyAccount, (state: AppState) => state.session.loyaltyRewards],
  loyaltyProgramSelectorInner,
);
