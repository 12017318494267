import {
  convertLoyaltyPointsToCents,
  DispatchType,
  DisplayableDiscount,
  DisplayableOrderItem,
  getDisplayablePrice,
} from '@wix/restaurants-client-logic';
import { TranslationFunction } from 'i18next';
import { ConditionReason } from '@wix/restaurants-client-logic/dist/types/types/Condition';
import { DisplayableReward } from '../CartDiscount/CartDiscount';

function getItemErrorString(
  error: ConditionReason,
  dispatchType: DispatchType,
  t: TranslationFunction,
  isMobile: boolean,
) {
  switch (error?.type) {
    case 'order_delivery_type':
      return dispatchType === 'delivery'
        ? t('checkout_confirm_modal_delivery_error')
        : t('checkout_confirm_modal_pickup_error');
    case 'order_delivery_time':
      return error.reason === 'soldout' ? t('cart_item_error_out_of_stock') : t('cart_item_error_not_available_future');
    case 'order_platform':
      return t(`cart_item_error_unavailable_${isMobile ? 'mobile' : 'desktop'}`);
    case 'false':
      return t('cart_item_error_not_available');
    default:
      return error?.type;
  }
}

function getVariationErrorString(displayableOrderItem: DisplayableOrderItem, t: TranslationFunction) {
  const allDisplayableChoices = displayableOrderItem.variations.flatMap((dv) => dv.choices);
  const invalidChoice = allDisplayableChoices.find((dc) => dc.isSelected && (dc.errors.length > 0 || dc.isDishDeleted));

  if (invalidChoice?.isDishDeleted) {
    return t('cart_item_error_dish_hidden');
  }

  const error = invalidChoice?.errors[0];

  switch (error?.type) {
    case 'order_delivery_time':
      return error.reason === 'soldout' ? t('cart_item_error_option_out_of_stock') : t('cart_item_error_dish_hidden');
    case 'false':
      return t('cart_item_error_dish_hidden');
    default:
      return error?.type;
  }
}

export function getErrorString(
  displayableOrderItem: DisplayableOrderItem,
  dispatchType: DispatchType,
  t: TranslationFunction,
  isMobile: boolean,
  isDishDeleted: boolean,
) {
  const error = displayableOrderItem.errors[0];

  if (isDishDeleted) {
    return t('cart_item_error_item_removed');
  }

  return getItemErrorString(error, dispatchType, t, isMobile) || getVariationErrorString(displayableOrderItem, t);
}

export function getDisplayableDiscountCondition(dd: DisplayableDiscount, t: TranslationFunction): string | undefined {
  if (dd.minPrice) {
    return t('cart_promo_min_order__price_label', { price: dd.displayableMinPrice });
  } else {
    return dd.errors[0]?.type;
  }
}

export function getDisplayableCouponError(error: {
  reason: string;
  deliveryType: DispatchType;
  min: number;
  platform: 'web' | 'mobileweb';
}) {
  const reason = error.reason;

  switch (reason) {
    default:
    case 'nonexistentCoupon':
      return 'promo_error_invalid_code';
    case 'couponExpired':
      return 'openrest_checkoutpaymentview_coupons_error_expired';
    case 'network':
      return 'openrest_checkoutpaymentview_error_network';
    case 'applyTo':
      return 'promo_error_invalid_dish';
    case 'order_items_price_min':
      return 'promo_error_min_order';
    case 'order_delivery_time':
      return 'promo_error_limit_generic';
    case 'order_delivery_type':
      return error.deliveryType === 'delivery'
        ? 'openrest_checkoutpaymentview_coupons_error_deliveronly'
        : 'openrest_checkoutpaymentview_coupons_error_pickuponly';
    case 'order_platform':
      return error.platform === 'web' ? 'promo_error_desktop_only' : 'promo_error_mobile_only';
  }
}
export function getDisplayableRewards({
  isActive,
  pointsRequired,
  maxRedeemablePoints,
  locale,
  currency,
  currencyValue,
}: {
  isActive: boolean;
  pointsRequired: number;
  maxRedeemablePoints: number;
  locale: string;
  currency: string;
  currencyValue: number;
}): DisplayableReward[] {
  const pointValues = [];

  if (isActive) {
    for (let i = pointsRequired; i <= maxRedeemablePoints; i += pointsRequired) {
      pointValues.push(i);
    }
  }

  return pointValues.map((pointsAmount) => {
    const price = convertLoyaltyPointsToCents({ pointsAmount, pointsRequired, currencyValue });
    return { points: pointsAmount, price, displayablePrice: getDisplayablePrice(price, locale, currency) };
  });
}
