import { Dispatch, VirtualDispatchType } from '@wix/restaurants-client-logic';
import { PickupDispatchInfo, Restaurant } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';

export const getVirtualDispatchTypeFromDispatch = (dispatch: Dispatch): VirtualDispatchType => {
  return dispatch.type !== 'takeout' ? dispatch.type : dispatch.contactlessDineIn ? 'dine-in' : 'takeout';
};

export const getDispatchTypeFromVirtual = (dispatchType: VirtualDispatchType) =>
  dispatchType !== 'dine-in' ? dispatchType : 'takeout';

export const getDineInInfo = (restaurant: Restaurant) => {
  const takeout = restaurant.deliveryInfos.find((info) => info.type === 'takeout');
  return takeout && (takeout as PickupDispatchInfo).contactlessDineInInfo!;
};
